import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import {
  AppFeaturedText,
  AppHero,
  AppFeaturedTextSecond
} from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface AppPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const AppPage: React.FC<AppPageProps> = ({ pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <PageHeader title="Nasza aplikacja" />
      <AppFeaturedText />
      <AppFeaturedTextSecond />
      <AppHero />
    </Layout>
  );
};

export default AppPage;
